* {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

html,
body {
    padding: 0;
    margin: 0;
    background-color: #eeeeee;
}

.box {
    width: 25%;
    height: 328px;
    margin: 0 auto;
    margin-top: calc(50vh - 164px);
    background-color: #ffffff;
    color: #000000de;
    -webkit-box-shadow: 0 4px 8px rgba(146, 151, 155, .15);
    box-shadow: 0 4px 8px rgba(146, 151, 155, .15);
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
}

@media all and (max-width: 1280px) {
    .box {
        width: 60%;
    }
}

@media all and (max-width: 568px) {
    .box {
        width: 80%;
    }
}

@media all and (max-width: 420px) {
    .box {
        width: 90%;
    }
}

.box h2 {
    opacity: 0.8;
    margin-top: 30px;
}

.box .form {
    text-align: left;
    max-width: 336px;
    box-sizing: border-box;
    margin: auto;
    padding: 1em 8px 0;
}

@media screen and (max-width: 650px) {
    .box .form {
        width: 100%;
    }
}

.box .form label {
    font-weight: bold;
    opacity: 0.9;
}

.box .form input {
    display: block;
    font-size: 1em;
    width: 100%;
    position: relative;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    text-align: left;
    font: inherit;
    color: #333333;
    padding: 0.5em 1em;
    border: 1px solid #cacaca;
    margin: 0;
    margin-top: 7.5px;
    border-radius: 5px;
}

.box .form input:focus {
    outline: 0;
    border-color: #777;
}

.box .btn-box {
    text-align: left;
    margin: auto;
    max-width: 22em;
    padding-top: 1rem;
    width: auto;
}

.box .btn-box button {
    max-width: unset;
    display: block;
    border-radius: 5px;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    font-weight: 300;
    background-color: #2c7cb0;
    padding: 0 8px;
    width: 100%;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    font: inherit;
    color: #fff;
    padding: 0.5em 0;
    border: 0;
    margin: 0;
    border-radius: 5px;
}

.logo-box {
    width: 100%;
    position: fixed;
    text-align: center;
    top: 20px;
}